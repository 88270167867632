<template>
<div>
  <CCard>
    <CCardBody>

      <ul class="nav nav-tabs nav-pills">
        <li class="nav-item">
          <a tab class="nav-link" @click.prevent="setActive('tabMissing')" :class="{ active: isActive('tabMissing') }" href='#tabMissing'>미수신 장비({{header.total}})</a>
        </li>
        <li class="nav-item ">
          <a tab class="nav-link" @click.prevent="setActive('tabTotal')" :class="{ active: isActive('tabTotal') }" href='#tabTotal'>전체 장비({{total_data.length}})</a>
        </li>
        <li class="nav-item ">
          <a tab class="nav-link" @click.prevent="setActive('tabNotused')" :class="{ active: isActive('tabNotused') }" href='#tabNotused'>미사용 장비({{notused_data.length}})</a>
        </li>
        <li class="nav-item ">
          <a tab class="nav-link" @click.prevent="setActive('tabDictionary')" :class="{ active: isActive('tabDictionary') }" href='#tabNotused'>Dictionary</a>
        </li>
      </ul>

      <div class="tab-content">
        <div class="tab-pane" id='tabMissing' :class="{ 'active show': isActive('tabMissing') }">
          <Missings
            ref="missingsDevice"
            @header="onHeader"
          />
        </div>

        <div class="tab-pane" id='tabTotal' :class="{ 'active show': isActive('tabTotal') }">
          <Total
            ref="totalDevice"
          />
        </div>

        <div class="tab-pane" id='tabNotused' :class="{ 'active show': isActive('tabNotused') }">
          <NotUsed
            ref="notUsedDevice"
          />
        </div>

        <div class="tab-pane  col-sm-12 col-lg-12" id='tabDictionary' :class="{ 'active show': isActive('tabDictionary') }">
          <Dictionary
            ref="dictionary"
            lines="17"
          />
        </div>

      </div>

    </CCardBody>
   </CCard>
</div>
</template>

<script>
import Vue from 'vue';
import loopback from '@/services/loopback';
import moment from 'moment';

import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm'
import Progress from '@/views/popups/Progress';

import { DATE } from '@/constants/constant'
import CronParser from 'cron-parser'

import Missings from '@/views/manage/Missings'
import Total from '@/views/manage/Total'
import NotUsed from '@/views/manage/NotUsed'
import Dictionary from '@/views/manage/Dictionary'

export default {
  name: 'DeviceMonitor',
  components: {
    BootstrapTable,
    Progress,
    Missings,
    Total,
    NotUsed,
    Dictionary
  },
  created() {
    this.user = this.$store.state.auth.user_info.user;

    this.queryDevice(2);
    this.queryDevice(1);
  },
  data () {
    return {
      user: null,
      sites: null,
      dates: [],
      data: [],
      total_data: [],
      notused_data: [],
      enableExcel: false,
      search: {},
      header: {
        total: 0
      },
      activeItem: 'tabMissing',
    }
  },
  mounted() {
    this.setActive('tabMissing');
  },
  computed: {
  },
  methods: {
  	isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      var self = this;
      this.activeItem = menuItem;
      var active_site_name = _.get(this.user, "active_site_name", "테크나인");
      switch (menuItem) {
        case 'tabTotal': {
          this.$refs.totalDevice.setData(self.total_data, active_site_name);
          break
        }
        case 'tabNotused': {
          this.$refs.notUsedDevice.setData(self.notused_data, active_site_name);
          break
        }
        case 'tabDictionary': {
          this.$refs.dictionary.setData();
          break;
        }
      }
    },
    onHeader(header) {
      this.header = header;
    },
    queryDevice(status = 2, site_guid, user_guid) {
      var self = this;
      var data = {
        status: status, // 0: active, 1: not use, 2: all, other: stop etc
        site_guid: undefined,
        user_guid: undefined
      }

      this.$store.dispatch('rest/method', {model:'devices', method:'getDevices', data:data})
        .then(res => {
          // Vue.$log.debug('queryDevices param:{0}, res:{1}', data, res)
          var tableData = status == 2 ? self.total_data : self.notused_data;

          res.data.forEach(function(site, index0) {
            site.infos.forEach(function(row, index) {
              var interval = CronParser.parseExpression(row.upload_cron)
              tableData.push({
                created_at: row.created_at,
                site_name: row.site_name,
                device_name: row.device_name,
                service_type: row.service_type,
                duedate: new Date(interval.prev()),
                latest: _.get(row, "data.latest"),
                upload_cron: row.upload_cron,
                device_guid: row.device_guid,
                site_guid: row.site_guid,
                guid: row.guid
              })
            })
          })
        })
        .catch((err) => {
          Vue.$log.error('queryDevices error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
      return;
    },

  }
}
</script>

<style scoped>
.search-marin {
  margin: 10px 0 10px;
}
</style>
