<template>
  <CRow>
    <CCol col="12" xl="12">
      <form enctype="multipart/form-data" novalidate>
        <div class="dropbox">
          <input type="file" name="files" @click="uploadFileName=null" @change="fileChange($event.target.name, $event.target.files);"
            accept="application/json" class="input-file">
            <p v-if="uploadFileName==null">
              Drag your [init-dictionary.json] file here to begin<br> or click to browse
            </p>
            <p v-if="uploadFileName!=null">
              <strong>{{ uploadFileName }}</strong> selected ...
            </p>
        </div>
      </form>
      <CRow>
        <CCol col="12" sm="12" xl="7" class="m-0 p-1">          
          <div id="upload_toolbar" class="pt-0">
            <CCol class="pl-0">
              <CButton color="info" class="float-left mr-1" :disabled="uploadFileName==null" @click="uploadFile()" >Upload</CButton>
              <CButton color="dark" class="float-left mr-5" :disabled="uploadFileName==null" @click="uploadFileName=null" >Reset</CButton>
              <CButton color="success" class="float-right" @click="downloadFile()" >Download</CButton>
            </CCol>
          </div>

          <v-table id="sensorTable" data-toolbar="#upload_toolbar" :columns="sensors.columns" :data="sensors.data" :options="sensors.options"
            data-group-by="true" data-group-by-field="code"></v-table>
        </CCol>
        <CCol col="12" sm="12" xl="5" class="m-0 p-1">
          <MemoView
            ref="memoView"
            :lines="lines"
          />

        </CCol>        
      </CRow>

    <Notify ref="notifyDialog"/>
    </CCol>
  </CRow>
</template>

<script>

import Vue from 'vue';
import loopback from '@/services/loopback';
import moment from 'moment';
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm'
import XLSX from 'xlsx';

import { SENSOR_TABLE_OPTIOINS, SENSOR_TABLE_COLUMS }
  from "@/constants/missingSettings"

import utils from '@/services/utils'

import MemoView from '@/views/manage/MemoView'

export default {
  name: 'Dictionary',
  components: {
    BootstrapTable: BootstrapTable,
    MemoView: MemoView
  },
  props: {
    lines: {
      type: String,
      default: "15"
    }
  },
  created() {
    this.uploadFileName = null;
  },
  data () {
    return {
      uploadFileName: null,
      sensors: {
        options: SENSOR_TABLE_OPTIOINS,
        columns: SENSOR_TABLE_COLUMS,
        data: [],
      },
      service_type: ""
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    refreshTable() {
      setTimeout(() => {
        $('#sensorTable').bootstrapTable('resetView');
      }, 1000)
    },
    fileChange(fieldName, fileList) {
      var selected = _.first(fileList);
      if (!selected) return;
      this.formData = new FormData();
      fileList.forEach((file) => {
        this.formData.append(fieldName, file, file.name)
      })
      this.uploadFileName = selected.name
      Vue.$log.info('selected file name: ', this.uploadFileName)
    },
    uploadFile() {
      // this.uploadFileName = null;
      Vue.$log.info('upload file name: ', this.uploadFileName)
      this.$store.dispatch('rest/method', {model:'dictionaries',method:'upload',data:this.formData})
        .then(res => {
          this.$refs.notifyDialog.show(this.uploadFileName + ' : DB 적용 완료')          
          this.uploadFileName = null;
        })
        .catch(err => {
          Vue.$log.error('upload error:', err.toString());
          this.$refs.notifyDialog.show(err.toString())          
        })
    },
    downloadFile() {
      this.$store.dispatch('rest/method',{model:'dictionaries',method:'download'})
        .then(res => {
          try {
            let blob = new Blob([JSON.stringify(res,null,2)], { type: "application/json" })
            let fileName = 'init-dictionary.json'

            if (window.navigator.msSaveOrOpenBlob) { // IE 10+
              window.navigator.msSaveOrOpenBlob(blob, fileName)
            } else { // not IE
              let link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.target = '_self'
              if (fileName) link.download = fileName
              link.click()
            }
          } catch (e) {
            // console.error(e)
            Vue.$log.error('download error:', e.toString());
          }                      
        })
        .catch(err => {
          Vue.$log.error('Device::reset error:', err.toString())
        })
    },
    setData() {
      if (!_.isEmpty(this.sensors.data))
        return
      var dictionary = utils.getDictionary();

      this.sensors.data = dictionary.sensor_type;
      this.$refs.memoView.setContent(JSON.stringify(dictionary.service_type,null,2));
    }
  }
}
</script>

<style scoped>
.search-marin {
  margin: 10px 0 10px;
}
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 10px 0;
}
</style>
