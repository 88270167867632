<template>
  <CRow>
    <CCol col="12" xl="12">
      <div id="toolbar" class="pt-0">
        <CCol class="pl-0">
          <date-picker class="float-left mr-1" v-model="dates" type="date" range placeholder="Select date range"></date-picker>
          <CButton color="success" class="float-left mr-1" @click="query()">
            조회
          </CButton>
          <CButton color="warning" class="float-left" @click="saveExcel()" :disabled="enableExcel == false">
            엑셀저장
          </CButton>
        </CCol>
      </div>

      <v-table id="missingTable" data-toolbar="#toolbar" :columns="columns" :data="data" :options="options"
        data-group-by="true" data-group-by-field="site_name"></v-table>

    </CCol>
    <Notify ref="notifyDialog"/>
  </CRow>
</template>

<script>

import Vue from 'vue';
import loopback from '@/services/loopback';
import moment from 'moment';
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm'
import XLSX from 'xlsx';

import { MISSING_TABLE_OPTIOINS, MISSING_TABLE_COLUMS }
  from "@/constants/missingSettings"
import { DATE } from '@/constants/constant'
import utils from '@/services/utils'

export default {
  name: 'Missings',
  components: {
    BootstrapTable: BootstrapTable,
  },
  created() {
    this.user = this.$store.state.auth.user_info.user;
    this.sites = this.$store.state.auth.user_info.sites

    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    this.dates.push(new Date(today.setTime(today.getTime() - 1 * 86400000)))
    this.dates.push(new Date(now.setTime(now.getTime())))
    this.active_site_name = _.get(this.user, "active_site_name", "테크나인");

    _.find(this.columns, { field: 'created_at' }).formatter = utils.dateFormatter;
    _.find(this.columns, { field: 'duedate' }).formatter = utils.dateFormatter;
    _.find(this.columns, { field: 'latest' }).formatter = this.dateFormatter;
    _.find(this.columns, { field: 'device_name' }).formatter = this.nameFormatter;
    _.find(this.columns, { field: 'site_name' }).formatter = this.siteFormatter;

    this.query();
  },
  data () {
    var table_options = _.cloneDeep(MISSING_TABLE_OPTIOINS);
    table_options.search = true;
    return {
      user: null,
      sites: null,
      options: table_options,
      columns: _.cloneDeep(MISSING_TABLE_COLUMS),
      data: [],
      dates: [],
      enableExcel: false,
      search: {},
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    getDeviceUrl(row) {
      return '#/device/' + _.get(row, "device_guid");
    },
    nameFormatter(value, row, index) {
      return "<a href='" + this.getDeviceUrl(row) + "'>" + _.get(row, "device_name") + "</a>";
    },
    dateFormatter(value, row, index) {
      var date_string = value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '미수신';
      return '<span style="color: red;">' + date_string + '</span>'
    },
    siteFormatter(value, row, index) {
      return "<a href='#/sites/{0}'>{1}</a>".format(row.site_guid, value || '-');
    },
    saveExcel() {
      var self = this;
      var write = function() {
        function makeFileName(name) {
          var from = moment(self.dates[0]).format('YYYY-MM-DD');
          var to = moment(self.dates[1]).format('YYYY-MM-DD');
          return name + "_" + from + "-" + to;
        }

        var missingData = $('#missingTable').bootstrapTable('getData');
        var r = [];

        var column_width = [20, 15, 20, 5, 5, 10, 25, 30];
        var wscols = [];
        column_width.forEach(function(item) {
          wscols.push({
            "wch": item
          })
        });

        missingData.forEach(function(data, i) {
          var row = {
            '조사 일시': utils.dateFormatter(data.created_at),
            '사이트명': data.site_name,
            '디바이스명': data.device_name,
            '서비스 타입': data.service_type,
            '수신 예상 일시': utils.dateFormatter(data.duedate),
            '최근 수신 일시': utils.dateFormatter(data.latest),
            '업로드 주기': data.upload_cron,
            'Site guid': data.site_guid,
            'Device guid': data.device_guid,
            'Missing guid': data.guid
          }
          r.push(row);
        })

        var ws = XLSX.utils.json_to_sheet(r);
        ws['!cols'] = wscols;

        var wb = XLSX.utils.book_new();
        var prefix = "미수신장비";
        XLSX.utils.book_append_sheet(wb, ws, prefix);
        var filename = makeFileName(prefix, false);
        XLSX.writeFile(wb, filename + ".xlsx");
      }

      this.$refs.progressDialog.show({message: '요청 데이터를 저장중입니다.', counter:50, callback: write})
    },
    query() {
      var self = this;
      var data = {
        date_type: DATE.TYPE.DAILY,
        site_guid: self.search.site_guid,
        device_guid: self.search.device_guid,
        from: moment(this.dates[0]).format('YYYY-MM-DD'),
        to: moment(this.dates[1]).format('YYYY-MM-DD'),
        limit: self.search.limit,
        skip: self.search.skip,
      }

      this.$store.dispatch('rest/method', {model:'missing_stats', method:'getStat', data:data})
        .then(res => {
          Vue.$log.debug('missings header:', res.header)
          self.data = [];

          res.data.forEach(function(site, index0) {
            site.missings.forEach(function(row, index) {
              self.data.push({
                created_at: row.created_at,
                site_name: row.site_name,
                device_name: row.device_name,
                service_type: row.service_type,
                duedate: _.get(row, "data.duedate") || _.get(row, "data.duetime"),
                latest: _.get(row, "data.latest"),
                upload_cron: row.upload_cron,
                device_guid: row.device_guid,
                site_guid: row.site_guid,
                guid: row.guid
              })
            })
          })
          self.enableExcel = self.data.length > 0 ? true : false;
          self.$emit('header', res.header);
          setTimeout(function() {
            var data = $('#missingTable').bootstrapTable('getData');
            var index = _.findIndex(data, {site_name: self.active_site_name});
            $('#missingTable').bootstrapTable('scrollTo', { unit: 'rows', value: index });
          }, 100)
        })
        .catch((err) => {
          Vue.$log.error('queryMissing error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
      return;
    },
  }
}
</script>