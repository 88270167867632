<template>
  <CRow>
    <CCol col="12" xl="12">
      <v-table id="notUsedTable" :columns="columns" :data="data" :options="options"
        data-group-by="true" data-group-by-field="site_name"></v-table>
    </CCol>

    <Notify ref="notifyDialog"/>
  </CRow>
</template>

<script>

import Vue from 'vue';
import loopback from '@/services/loopback';
import moment from 'moment';
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm'
import XLSX from 'xlsx';

import { MISSING_TABLE_OPTIOINS, MISSING_TABLE_COLUMS }
  from "@/constants/missingSettings"
import utils from '@/services/utils'

import Progress from '@/views/popups/Progress';

export default {
  name: 'NotUsed',
  components: {
    BootstrapTable: BootstrapTable,
  },
  created() {
    _.find(this.columns, { field: 'created_at' }).formatter = utils.dateFormatter;
    _.find(this.columns, { field: 'duedate' }).formatter = utils.dateFormatter;
    _.find(this.columns, { field: 'latest' }).formatter = this.dateFormatter;
    _.find(this.columns, { field: 'device_name' }).formatter = this.nameFormatter;
    _.find(this.columns, { field: 'site_name' }).formatter = this.siteFormatter;
  },
  data () {
    var table_options = _.cloneDeep(MISSING_TABLE_OPTIOINS);
    table_options.search = true;
    return {
      user: null,
      sites: null,
      options: table_options,
      columns: _.cloneDeep(MISSING_TABLE_COLUMS),
      data: []
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    getDeviceUrl(row) {
      return '#/device/' + _.get(row, "device_guid");
    },
    nameFormatter(value, row, index) {
      return "<a href='" + this.getDeviceUrl(row) + "'>" + _.get(row, "device_name") + "</a>";
    },
    dateFormatter(value, row, index) {
      var date_string = value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '미수신';
      return '<span style="color: red;">' + date_string + '</span>'
    },
    siteFormatter(value, row, index) {
      return "<a href='#/sites/{0}'>{1}</a>".format(row.site_guid, value || '-');
    },
    setData(data, active_site_name) {
      if (!_.isEmpty(this.data))
        return
      this.data = data;
      // setTimeout(function() {
      //   var data = $('#notUsedTable').bootstrapTable('getData');
      //   var index = _.findIndex(data, {site_name: active_site_name});
      //   $('#notUsedTable').bootstrapTable('scrollTo', { unit: 'rows', value: index });
      // }, 100)
    }
  }
}
</script>

<style scoped>
.search-marin {
  margin: 10px 0 10px;
}
</style>
