const MISSING_TABLE_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
    };
    },
    formatNoMatches: function() {
        return '-';
    },
    cache: false,
    height: 510,
    striped: true,
    pagination: false,
    pageSize: 10,
    pageList: [5, 10, 25, 50, 100, 200],
    search: false,
    showColumns: false,
    showRefresh: false,
    minimumCountColumns: 2,
    clickToSelect: false,
    singleSelect: true,
    showToggle: false,
    maintainSelected: true,
    sortName: 'site_name',
    sortOrder: 'asc',
    uniqueId: 'guid',
}

const MISSING_TABLE_COLUMS = [
  {
    field: 'created_at',
    title: '조사 일시',
    align: 'right',
    valign: 'top',
    visible: false,
    sortable: true,
  }, {
    field: 'site_name',
    title: '사이트명',
    align: 'center',
    valign: 'top',
    sortable: true,
  }, {
    field: 'device_name',
    title: '디바이스명',
    align: 'center',
    valign: 'top',
    sortable: false,
  }, {
    field: 'service_type',
    title: '서비스 타입',
    align: 'center',
    valign: 'top',
    sortable: false,
    visible: false
  }, {
    field: 'duedate',
    title: '수신 예상 일시',
    align: 'center',
    valign: 'top',
    sortable: false,
  }, {
    field: 'latest',
    title: '최근 수신 일시',
    align: 'center',
    valign: 'top',
    sortable: false,
  }, {
    field: 'upload_cron',
    title: '업로드 주기',
    align: 'center',
    valign: 'top',
    sortable: false
  }, {
    field: 'device_guid',
    title: '디바이스ID',
    align: 'center',
    valign: 'top',
    visible: false
  }, {
    field: 'site_guid',
    title: '사이트ID',
    align: 'center',
    valign: 'top',
    visible: false
  }, {
    field: 'guid',
    title: '미수신ID',
    align: 'center',
    valign: 'top',
    visible: false
  }
]

const SENSOR_TABLE_OPTIOINS = {
  rowStyle: function(row, index) {
      return {
          classes: 'none'
  };
  },
  formatNoMatches: function() {
      return '-';
  },
  cache: false,
  height: 350,
  striped: true,
  pagination: false,
  pageSize: 10,
  pageList: [5, 10, 25, 50, 100, 200],
  search: true,
  showColumns: false,
  showRefresh: false,
  minimumCountColumns: 2,
  clickToSelect: true,
  singleSelect: true,
  showToggle: false,
  maintainSelected: true,
  sortName: 'code',
  sortOrder: 'asc'
}

const SENSOR_TABLE_COLUMS = [
  {
    field: 'code',
    title: 'Code',
    align: 'center',
    valign: 'top',
    sortable: true,
  }, {
    field: 'id',
    title: 'Id',
    align: 'center',
    valign: 'top',
    sortable: false,
  }, {
    field: 'value',
    title: '센서',
    align: 'center',
    valign: 'top',
    visible: true,
    sortable: true,
  }, {
    field: 'unit',
    title: '단위',
    align: 'center',
    valign: 'top',
    sortable: false,
  }
]

export {
    MISSING_TABLE_OPTIOINS,
    MISSING_TABLE_COLUMS,
    SENSOR_TABLE_OPTIOINS,
    SENSOR_TABLE_COLUMS
}
