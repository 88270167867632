<template>
  <div>
    <div class="card card-body">
      <div class="card-header">
        <div class="row">
          <div class="col-md-8">        
            <strong>Service Types</strong>
          </div>          
          <div class="d-md-down-none col-md-4">        
            <CButton color="info" class="float-right" size="sm" @click="changeMemo()">{{memoInfo.btnText}}</CButton>
            <CButton color="secondary" class="float-right mr-1" size="sm" v-show="memoInfo.readonly == false" @click="changeMemo('cancel')">취소</CButton>
          </div>          
        </div>          
      </div>
      <div class="card-body">
        <textarea id="memoText" class='form-control' :readonly="memoInfo.readonly" v-model='memoInfo.content' :rows='lines' placeholder='' ></textarea>
      </div>
    </div>

    <Confirm
        ref="confirmDialog"
        @hide="hideModal"
    />

    <Notify ref="notifyDialog"/>

  </div>

</template>

<script>
import loopback from '@/services/loopback';
import EventBus from '@/services/EventBus'
import utils from '@/services/utils'

export default {
  name: 'MemoView',
  components: {
  },
  props: {
    lines: {
      type: String,
      default: 12
    }
  },
  created: function() {
    this.user = this.$store.state.auth.user_info.user;
    this.sites = this.$store.state.auth.user_info.sites

    var userInfo = utils.getStorage('userInfo')
  },
  beforeDestroy(){
  },
  data () {
    return {
      agency_guid: '',
      memoInfo: {
        content: '',
        btnText: '수정',
        readonly: true
      },      
    }
  },
  methods: {
    hideModal(params) {
      if (_.isUndefined(params)) {
        return; // cancel command
      }
      switch (params.name) {
        case 'Confirm':
          if (params.which === 'update' && params.data === 'confirm') {
            this.memoInfo.readonly = true
            this.memoInfo.btnText = '수정'
            this.saveMemoInfo();
          }
          break;
        default:
          break
      }
    },
    changeMemo(mode) {
      if (mode === 'cancel') {
        this.memoInfo.readonly = true
        this.memoInfo.btnText = '수정'
      } else
      if (this.memoInfo.readonly === true) {
        this.memoInfo.readonly = false
        this.memoInfo.btnText = '저장'
      } else {
        this.$refs.confirmDialog.show('수정 하시겠습니까?', '확인', undefined, 'update');
      }
    },
    saveMemoInfo() {
      console.log('MemoView::saveMemoInfo');
    },
    setContent(content) {
      this.memoInfo.content = content;
    }
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  /* margin: 0.00rem; */
  margin: 0.3rem;
  padding: 0.01rem;
  /* padding: 0.50rem; */
}
</style>